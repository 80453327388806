<template>
  <div class="container">
    <div class="centent">
      <div class="title">
        <div class="title-top">
          <div class="left">
            <div v-if="wishingedit != recordId">
              {{ wishName }}
            </div>
            <input
              v-else
              v-model="wishingeditname"
              style="
                outline: none;
                padding-left: 10px;
                width: 150px;
                height: 30px;
                border: 1px #d3136c solid;
                border-radius: 5px;
                margin-right: 10px;
              "
            />
            <i
              v-if="wishingedit != recordId"
              @click="toEdit(recordId)"
              style="cursor: pointer; margin-left: 30px"
              class="el-icon-edit-outline"
            ></i>
            <div
              style="
                width: 50px;
                height: 25px;
                border: 1px #d3136c solid;
                font-size: 14px;
                line-height: 25px;
                text-align: center;
                margin-left: 30px;
                border-radius: 5px;
              "
              v-else
            >
              <span style="margin: 0 auto" @click="editwishing(recordId)"
                >保存</span
              >
            </div>
          </div>
          <div class="right">
            <el-row>
              <el-button
                :class="checked == 'data' ? 'button-data' : 'button-data-data'"
                @click="check('data')"
              >
                全部{{ this.dataList.length }}
              </el-button>
              <el-button
                :class="checked == 'r001' ? 'button-r001' : 'button-r001-r001'"
                @click="check('r001')"
              >
                难录取{{ this.r001List.length }}
              </el-button>
              <el-button
                :class="checked == 'r002' ? 'button-r002' : 'button-r002-r002'"
                @click="check('r002')"
              >
                可冲刺{{ this.r002List.length }}
              </el-button>
              <el-button
                :class="checked == 'r003' ? 'button-r003' : 'button-r003-r003'"
                @click="check('r003')"
              >
                稳妥{{ this.r003List.length }}
              </el-button>
              <el-button
                :class="checked == 'r004' ? 'button-r004' : 'button-r004-r004'"
                @click="check('r004')"
              >
                保底{{ this.r004List.length }}
              </el-button>
            </el-row>
          </div>
        </div>
        <div class="title-main">
          <div class="left">
            我的成绩：{{ comScore }}分&nbsp;{{ firstMajor }}
          </div>
          <div class="right">
            <el-button
              :disabled="disable"
              class="main-btn"
              @click="saveOrModify()"
              >{{ drag ? "修改" : "保存" }}</el-button
            >
            <!-- <el-button class="main-btn">打印</el-button> -->
            <el-button class="main-btn" @click="downloadExcel">下载</el-button>
          </div>
        </div>
        <div class="title-bottom">
          <div style="width: 30px">排序</div>
          <div style="width: 90px; margin-left: 40px">录取概率</div>
          <div style="width: 230px; margin-left: 30px">招生院校</div>
          <div style="width: 150px; text-align: center; margin-left: 55px">
            招生专业
          </div>
          <div style="width: 105px; margin-left: 55px">
            {{ this.titleYear[0] + 1 }}年招生计划
          </div>
          <div style="width: 100px; text-align: center; margin-left: 25px">
            历年数据批次线<br />
            (文化/专业)
          </div>
          <div style="width: 35px; margin-left: 40px">
            {{ computeYear }} {{ this.titleYear[0] }}
          </div>
          <div style="width: 35px; margin-left: 40px">
            {{ this.titleYear[1] }}
          </div>
          <div style="width: 35px; margin-left: 40px">
            {{ this.titleYear[2] }}
          </div>
          <div></div>
        </div>
      </div>
      <div class="main">
        <ul
          infinite-scroll-distance="1px"
          :infinite-scroll-disabled="disable"
          style="
            overflow: auto;
            max-height: 800px;
            width: 1220px;
            margin: 0 auto;
          "
        >
          <Container @drop="onDrop" :non-drag-area-selector="dragElement">
            <Draggable
              v-for="(item, index) in this.dataList"
              :key="item.indexId"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <li
                class="ul-li unDrag"
                v-show="
                  checked == 'data'
                    ? true
                    : checked == item.probabilityTypeCode
                    ? true
                    : false
                "
              >
                <div
                  style="
                    height: 100px;
                    width: 1200px;
                    margin-left: 10px;
                    display: flex;
                    user-select: none;
                    position: relative;
                  "
                >
                  <div style="width: 20px; min-width: 20px; text-align: center">
                    {{ index + 1 }}
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      margin-left: 30px;
                      align-items: center;
                      width: 90px;
                    "
                  >
                    <div
                      :style="{
                        color:
                          item.probabilityTypeCode == 'r001'
                            ? '#f05138'
                            : item.probabilityTypeCode == 'r002'
                            ? '#f59e49'
                            : item.probabilityTypeCode == 'r003'
                            ? '#2ccae1'
                            : item.probabilityTypeCode == 'r004'
                            ? '#28E197'
                            : '#576ae9',
                      }"
                      style="font-size: 26px; margin-bottom: 10px"
                    >
                      {{ item.probability }}%
                    </div>
                    <div
                      :style="{
                        color:
                          item.probabilityTypeCode == 'r001'
                            ? '#f05138'
                            : item.probabilityTypeCode == 'r002'
                            ? '#f59e49'
                            : item.probabilityTypeCode == 'r003'
                            ? '#2ccae1'
                            : item.probabilityTypeCode == 'r004'
                            ? '#28E197'
                            : '#576ae9',
                      }"
                    >
                      {{ item.probabilityTypeName }}
                    </div>
                  </div>
                  <div style="margin-left: 35px; min-width: 230px">
                    <div style="display: flex; align-items: center">
                      <div
                        style="
                          font-size: 24px;
                          width: 145px;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          cursor: pointer;
                        "
                        :title="item.schoolName"
                      >
                        {{ item.schoolName }}
                      </div>
                      <div
                        style="
                          font-size: 16px;
                          margin-left: 20px;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          width: 130px;
                          cursor: pointer;
                        "
                        :title="item.provinceName + item.regionName"
                      >
                        {{ item.provinceName }} {{ item.regionName }}
                      </div>
                    </div>
                    <div
                      style="color: #d3136c; margin-top: 15px; font-size: 14px"
                    >
                      {{ item.schoolType }}&nbsp;&nbsp;/硕士点({{
                        item.masterFst + item.masterSec
                      }})&nbsp;&nbsp;/博士点({{
                        item.doctorFst + item.doctorSec
                      }})
                    </div>
                    <div style="margin-top: 15px; font-size: 14px">
                      院校代码&nbsp;&nbsp;{{
                        item.schoolCode
                      }}&nbsp;&nbsp;&nbsp;&nbsp;评级：6级
                    </div>
                  </div>
                  <div
                    style="
                      width: 150px;
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      font-size: 14px;
                    "
                  >
                    <div
                      style="
                        margin-top: 8px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 100%;
                        text-align: center;
                        cursor: pointer;
                      "
                      :title="item.schoolMajorName"
                    >
                      {{ item.schoolMajorName }}
                    </div>
                    <div
                      style="
                        margin-top: 20px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 100%;
                        text-align: center;
                      "
                      :title="`专业代码:${item.schoolMajorCode}`"
                    >
                      专业代码:{{ item.schoolMajorCode }}
                    </div>
                    <div
                      style="
                        margin-top: 15px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 100%;
                        text-align: center;
                        cursor: pointer;
                        color: #d3136c;
                      "
                      :title="
                        item.majorRanking
                          ? `${item.majorRanking}${
                              item.isImportant ? '/国家一流专业' : ''
                            }`
                          : '无'
                      "
                    >
                      评级：{{ item.majorRanking ? item.majorRanking : "无"
                      }}{{ item.isImportant ? "/国家一流专业" : "" }}
                    </div>
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      margin-left: 60px;
                    "
                  >
                    <div style="margin-top: 5px; font-size: 20px">
                      {{ item.planNums }}人
                    </div>
                    <div style="margin-top: 15px; font-size: 14px">
                      {{ item.collegeYear }}/￥{{ item.collegeFee }}
                    </div>
                  </div>
                  <div
                    style="
                      margin-left: 105px;
                      font-size: 14px;
                      position: absolute;
                      top: 0;
                      right: 320px;
                    "
                  >
                    <div>计划数</div>
                    <div style="margin-top: 20px">录取分数</div>
                    <div style="margin-top: 20px">投档位次</div>
                  </div>
                  <div
                    v-for="year in item.yearlyScores"
                    :key="year.yearName"
                    :style="computeStyle(year.yearName)"
                  >
                    <div style="width: 35px">
                      {{
                        year.yearPlanNbr
                          ? year.yearPlanNbr
                          : year.yearPlanNbr == "0"
                          ? "0"
                          : "-"
                      }}
                    </div>
                    <div style="margin-top: 20px; width: 35px">
                      {{
                        year.yearAdmScore
                          ? year.yearAdmScore
                          : year.yearAdmScore == "0"
                          ? "0"
                          : "-"
                      }}
                    </div>
                    <div style="margin-top: 20px; width: 35px">
                      {{
                        year.yearRank
                          ? year.yearRank
                          : year.yearRank == "0"
                          ? "0"
                          : "-"
                      }}
                    </div>
                  </div>
                  <div
                    v-if="drag == false"
                    style="
                      display: flex;
                      align-items: center;
                      margin-left: 20px;
                      position: absolute;
                      top: 40px;
                      right: 5px;
                    "
                  >
                    <div
                      style="
                        display: flex;
                        flex-direction: column;
                        margin-right: 20px;
                      "
                    ></div>
                    <div>
                      <i
                        class="el-icon-rank"
                        style="color: #bfbfbf; cursor: move; margin-right: 10px"
                      ></i>
                      <el-popconfirm
                        title="确定删除该条数据吗？"
                        @confirm="deleteItem(item.indexId)"
                      >
                        <template #reference>
                          <i
                            class="el-icon-delete"
                            style="color: #bfbfbf; cursor: pointer"
                          ></i>
                        </template>
                      </el-popconfirm>
                    </div>
                  </div>
                </div>
              </li>
            </Draggable>
          </Container>
        </ul>
      </div>
      <div class="footer">
        重要提示：<br />
        注：以上志愿表为模拟填报志愿表，正式填报请登录省考试院指定填报网站;
        申明：智能推荐使用当年招生计划和参考历年录取数据的志愿模拟填报平台，参考数据均来自省考试院，正式填报过程中如遇到数据错误，请参阅省考试院发布的相关招生计划书籍或拨打客服热线。
      </div>
    </div>
  </div>
</template>

<script>
import {
  queryById,
  detailList,
  saveWishing,
  download,
  updateWishing,
} from "../../../api/index";
import axios from "axios";
import { Container, Draggable } from "vue-smooth-dnd";
export default {
  name: "detail",
  components: {
    Container,
    Draggable,
  },
  data() {
    return {
      recordId: "",

      disable: false,
      wishName: "",
      comScore: "",
      firstMajor: "",
      dragElement: ".ul-li",
      checked: "data",
      // 展示列表
      dataList: [],
      dataList1: [],
      r001List: [],
      r002List: [],
      r003List: [],
      r004List: [],

      nodata: false,
      drag: true,

      titleYear: [],
      wishingedit: "",
      wishingeditname: "",
    };
  },
  computed: {
    computeYear() {
      if (this.dataList[0] !== undefined) {
        this.dataList[0].yearlyScores.forEach((element) => {
          this.titleYear.push(element.yearName);
        });
      }
    },
    computeStyle() {
      return function name(yearName) {
        let arr = this.titleYear;
        let min = Math.min(...arr);
        let max = Math.max(...arr);
        return {
          textAlign: "center",
          position: "absolute",
          right: min == yearName ? "70px" : max == yearName ? "220px" : "145px",
        };
      };
    },
  },
  mounted() {
    this.getRouterQuery();
    this.getwishing();
    this.getDetailList();
  },
  methods: {
    editwishing(id) {
      let data = {
        recordId: id,
        wishName: this.wishingeditname,
      };
      updateWishing(data).then((res) => {
        if (res.code == 200) {
          this.getwishing();
          this.wishingedit = "";
        }
      });
    },
    toEdit(data) {
      this.wishingedit = data;
      console.log(data);
    },
    deleteItem(index) {
      console.log(index, "index");
      if (index >= 0 && index < this.dataList.length) {
        this.dataList.splice(index, 1);
        // 重新分配 indexId
        this.dataList = this.dataList.map((item, idx) => ({
          ...item,
          indexId: idx,
        }));
        this.takedataList();
      }
    },
    downloadExcel() {
      download(this.recordId).then((res) => {
        console.log(res);
        let blob = new Blob([res.data], { type: "application/octet-stream" });
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.href = url;
        let headers = decodeURIComponent(
          res.headers["content-disposition"]
        ).split("=")[1];
        console.log("请求头", headers);
        link.download = headers;
        link.click();
        window.URL.revokeObjectURL(url);
      });
    },
    async saveOrModify() {
      if (!this.disable) {
        this.drag = !this.drag;
        if (this.drag) {
          this.dragElement = ".ul-li";
          let wishingId = this.recordId;
          let ids = [];
          this.dataList.forEach((item) => {
            ids.push(item.recordId);
          });
          console.log(ids);
          const data = {
            wishingId: wishingId,
            recordIds: ids,
          };
          await saveWishing(data);
        } else {
          this.dragElement = ".ul-li-1";
        }
        this.disable = true;
        setTimeout(() => {
          this.disable = false;
        }, 1000);
      }
    },
    // 根据志愿概率分类
    takedataList() {
      console.log("23");
      let list = this.dataList;
      this.r001List = [];
      this.r002List = [];
      this.r003List = [];
      this.r004List = [];
      for (let index = 0; index < list.length; index++) {
        if (list[index].probabilityTypeCode == "r001") {
          this.r001List.push(list[index]);
        } else if (list[index].probabilityTypeCode == "r002") {
          this.r002List.push(list[index]);
        } else if (list[index].probabilityTypeCode == "r003") {
          this.r003List.push(list[index]);
        } else if (list[index].probabilityTypeCode == "r004") {
          this.r004List.push(list[index]);
        }
      }
    },
    getRouterQuery() {
      this.recordId = this.$route.query.data;
      // console.log('asd',this.recordId)
      // return this.$route.query;
    },
    onDrop(dropResult) {
      // 使用自定义的 applyDrag 函数
      let newItems = this.applyDrag(this.dataList, dropResult);

      // 重新分配 indexId
      newItems = newItems.map((item, index) => ({
        ...item,
        indexId: index,
      }));
      this.dataList = newItems;
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult;
      if (removedIndex === null && addedIndex === null) return arr;
      const result = [...arr];
      let itemToAdd = payload;

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }
      console.log("result", result);
      this.dataList = result;
      return result;
    },
    getwishing() {
      // 247205952531464192

      queryById(this.recordId).then((res) => {
        this.wishName = res.data.wishName;
        this.comScore = res.data.comScore;
        this.firstMajor = res.data.firstMajor_dictText;
      });
    },
    getDetailList() {
      detailList(this.recordId).then((res) => {
        console.log("res", res);
        this.dataList = res.data;
        this.dataList1 = res.data;
        for (let index = 0; index < this.dataList1.length; index++) {
          this.dataList[index].indexId = index;
        }
        this.takedataList();
      });
    },
    check(id) {
      this.checked = id;
      let listLength = this[`${id}List`].length;
      console.log(listLength);
      if (listLength == 0) {
        this.nodata = true;
      } else {
        this.nodata = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  background: #f5f5f5;
  .centent {
    width: 1280px;
    min-height: 590px;
    // border: #ccc 1px solid;
    margin: 0px auto;
    margin-top: 39px;
    // overflow: hidden;
    .title {
      width: 1280px;
      height: 220px;
      background: #ffffff;
      .title-top {
        width: 1220px;
        // height: 50px;
        display: flex;
        padding: 20px 30px 0px 30px;
        justify-content: space-between;
        .left {
          font-size: 22px;
          display: flex;
          align-items: center;
        }
        .right {
          .button-data {
            background-color: #d3136c;
            color: #fff;
            border: 1px solid #d3136c;
          }
          .button-r001 {
            background-color: #f05138;
            color: #fff;
            border: #f05138 1px solid;
          }
          .button-r002 {
            background-color: #f59e49;
            border: 1px solid #f59e49;
            color: #fff;
          }
          .button-r003 {
            background-color: #2ccae1;
            border: 1px solid #2ccae1;
            color: #fff;
          }
          .button-r004 {
            background-color: #28e197;
            border: 1px solid #28e197;
            color: #fff;
          }
          .button-6 {
            background-color: #576ae9;
            border: 1px solid #576ae9;
            color: #fff;
          }
          .button-7 {
            background-color: #bbbbbb;
            border: 1px solid #bbbbbb;
            color: #fff;
          }
          .button-data-data {
            color: #d3136c;
            border: 1px solid #d3136c;
          }
          .button-r001-r001 {
            color: #f05138;
            border: #f05138 1px solid;
          }
          .button-r002-r002 {
            border: 1px solid #f59e49;
            color: #f59e49;
          }
          .button-r003-r003 {
            border: 1px solid #2ccae1;
            color: #2ccae1;
          }
          .button-r004-r004 {
            border: 1px solid #28e197;
            color: #28e197;
          }
          .button-6-6 {
            border: 1px solid #576ae9;
            color: #576ae9;
          }
          .button-7-7 {
            border: 1px solid #bbbbbb;
            color: #bbbbbb;
          }
        }
      }
      .title-main {
        width: 1220px;
        height: 30px;
        padding: 20px 30px 0px 30px;
        color: #b5b5b5;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        .main-btn {
          width: 66px;
          height: 32px;
          color: #d3136c;
          background: #ffd5c8;
          border: #d3136c;
        }
      }
      .title-bottom {
        display: flex;
        width: 1220px;
        padding: 30px 30px 0px 30px;
        // border: #2397fe 1px solid;
        color: #aeaeae;
        font-size: 14px;
        margin-bottom: 40px;
      }
    }
    .main {
      width: 1280px;
      max-height: 800px;
      background: #ffffff;
      .ul-li {
        display: flex;
        align-items: center;
        height: 160px;
        width: 1220px;
        // border: #bbbbbb solid 1px;
        // 吸附的对齐方式，表示开头对其
        scroll-snap-align: start;
        // 表示要始终停止，不能跳过
        scroll-snap-stop: always;
        border-top: 1px dashed #707070;
      }
    }
    .footer {
      width: 1220px;
      height: 60px;
      padding: 20px 30px 20px 30px;
      margin-top: 20px;
      background: #ffffff;
      // overflow: hidden;
    }
  }
}
</style>