<template>
  <div class="centent">
    <div class="left">
      <div class="left-top">
        <div class="avatar">
          <img
            src="@/assets/images/homeView/silveryImage.png"
            class="avatar-image"
          />
          <div class="username">{{ this.name }}</div>
          <button class="open-member">开通会员</button>
        </div>
        <div class="information">
          <div class="info-box">
            <div class="info-title">首选科目</div>
            <div class="info-main">
              {{ this.studentDetail.firstMajor_dictText }}
            </div>
          </div>
          <div class="info-box">
            <div class="info-title">专业类别</div>
            <div class="info-main">
              {{ this.studentDetail.artWard_dictText }}
            </div>
          </div>
          <div class="info-box">
            <div class="info-title">专业成绩</div>
            <div class="info-main">{{ this.studentDetail.majorScore }}</div>
          </div>
          <div class="info-box">
            <div class="info-title">文化成绩</div>
            <div class="info-main">{{ this.studentDetail.cultureScore }}</div>
          </div>
        </div>
      </div>
      <div class="left-main">
        <div class="left-main-div">
          <div class="main-top">{{ this.NumberOfConcerns }}</div>
          <div class="main-bottom">关注院校</div>
        </div>
        <div class="left-main-div div-color" @click="toVolunteer">
          <div class="main-top">{{ this.wishingCount }}</div>
          <div class="main-bottom">我的志愿表</div>
        </div>
      </div>
      <div class="left-bottom">
        <div
          :class="mainIndex == 1 ? 'left-bottom-div-active' : 'left-bottom-div'"
          @click="toMain(1)"
        >
          <img
            :src="
              mainIndex == 1
                ? require('../../assets/images/personalCenter/01.png')
                : require('../../assets/images/personalCenter/01-.png')
            "
            alt=""
          />个人信息
        </div>
        <!-- <div
          :class="mainIndex == 6 ? 'left-bottom-div-active' : 'left-bottom-div'"
          @click="toMain(6)"
        >
          <img
            :src="
              mainIndex == 6
                ? require('../../assets/images/personalCenter/06.png')
                : require('../../assets/images/personalCenter/06-.png')
            "
            alt=""
          />退出
        </div> -->
      </div>
    </div>
    <div class="right">
      <div class="right-main" v-if="mainIndex == 1">
        <div class="top">
          <el-menu
            class="el-menu-demo"
            mode="horizontal"
            :default-active="itemIndex"
            active-text-color="#d3136c"
            @select="handleSelect"
          >
            <el-menu-item index="1" style="font-size: 18px"
              >基础信息管理</el-menu-item
            >
            <el-menu-item index="2" style="font-size: 18px"
              >统考成绩管理</el-menu-item
            >
            <el-menu-item index="3" style="font-size: 18px"
              >校考成绩管理</el-menu-item
            >
          </el-menu>
        </div>
        <div class="main">
          <div class="main-1" v-if="itemIndex == 1">
            <div class="li">
              <div class="left">
                省&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;份
              </div>
              <div class="main">
                <el-select
                  v-model="province"
                  placeholder="请选择"
                  @change="changeProvince"
                  disabled
                >
                  <el-option
                    v-for="item in provinceList"
                    :key="item.chartOrder"
                    :label="item.regionName"
                    :value="item.recordId"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="right"></div>
            </div>
            <div class="li">
              <div class="left">高考年份</div>
              <div class="main">
                <el-select
                  v-model="yearly"
                  placeholder="请选择"
                  @change="changeProvince"
                >
                  <el-option
                    v-for="item in yearlyList"
                    :key="item.yearName"
                    :label="item.yearName"
                    :value="item.recordId"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="right"></div>
            </div>
            <div class="li">
              <div class="left">艺考方向</div>
              <div class="main">
                <el-cascader
                  v-if="drow"
                  v-model="artWard"
                  :options="options"
                  :props="{
                    expandTrigger: 'hover',
                    value: 'recordId',
                    label: 'artName',
                    children: 'children',
                    checkStrictly: false,
                    emitPath: true,
                  }"
                  @getCheckedNodes="getCheckedNodes"
                  :show-all-levels="true"
                ></el-cascader>
              </div>
              <div class="right"></div>
            </div>
            <div class="li">
              <div class="left">首选科目</div>
              <div class="main">
                <el-radio
                  v-model="firstMajor"
                  :key="item.index"
                  :label="item.recordId"
                  v-for="item in this.firstMajors"
                  >{{ item.subjectName }}</el-radio
                >
              </div>
              <div class="right"></div>
            </div>
            <div class="li">
              <div class="left">再选科目</div>
              <div class="main">
                <el-checkbox-group v-model="otherMajor" :min="0" :max="2">
                  <el-checkbox
                    v-for="city in this.cities"
                    :label="city.recordId"
                    :value="city.recordId"
                    :key="city.subjectName"
                    >{{ city.subjectName }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
              <div class="right"></div>
            </div>
            <div class="li">
              <div class="left">
                性&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;别
              </div>
              <div class="main">
                <el-radio
                  v-model="sex"
                  :key="item.recordId"
                  :label="item.recordId"
                  v-for="item in this.sexList"
                  >{{ item.enumName }}</el-radio
                >
              </div>
              <div class="right"></div>
            </div>
            <div class="li">
              <div class="left">
                姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名
              </div>
              <div class="main">
                <el-input v-model="name" placeholder="请输入姓名"></el-input>
              </div>
              <div class="right"></div>
            </div>
            <div class="li">
              <div class="left">高中学校</div>
              <div class="main">
                <el-input
                  v-model="schoolName"
                  placeholder="请输入高中学校"
                ></el-input>
              </div>
              <div class="right"></div>
            </div>
          </div>
          <div class="main-1" v-if="itemIndex == 2">
            <div class="li">
              <div class="left">统考成绩</div>
              <div class="main">
                <el-input
                  v-model="majorScore"
                  placeholder="请输入统考成绩，分数区间为0-300。"
                ></el-input>
              </div>
              <div class="right"></div>
            </div>
            <div class="li">
              <div class="left">统考排名</div>
              <div class="main">
                <el-input
                  v-model="majorRank"
                  placeholder="请输入统考排名"
                ></el-input>
              </div>
              <div class="right"></div>
            </div>
            <div class="li">
              <div class="left">文化成绩</div>
              <div class="main">
                <el-input
                  v-model="cultureScore"
                  placeholder="请输入文化成绩，分数区间为0-750。"
                ></el-input>
              </div>
              <div class="right"></div>
            </div>
            <div class="li">
              <div class="left">综合位次</div>
              <div class="main">
                <el-input
                  v-model="cultureRank"
                  placeholder="请输入综合位次"
                ></el-input>
              </div>
              <div class="right"></div>
            </div>
            <div class="li">
              <div class="left">语文成绩</div>
              <div class="main">
                <el-input
                  v-model="ywScore"
                  placeholder="请输入语文成绩，分数区间为0-150。"
                ></el-input>
              </div>
              <div class="right"></div>
            </div>
            <div class="li">
              <div class="left">英语成绩</div>
              <div class="main">
                <el-input
                  v-model="enScore"
                  placeholder="请输入英语成绩，分数区间为0-150。"
                ></el-input>
              </div>
              <div class="right"></div>
            </div>
            <div class="li">
              <div class="left">数学成绩</div>
              <div class="main">
                <el-input
                  v-model="sxScore"
                  placeholder="请输入数学成绩，分数区间为0-150。"
                ></el-input>
              </div>
              <div class="right"></div>
            </div>
          </div>
          <div class="main-1" v-if="itemIndex == 3">
            <div class="list" v-if="!show1">
              <div class="title">
                <div style="width: 90px"></div>
                <div style="width: 130px">院校名称</div>
                <div style="width: 130px">报考专业</div>
                <div style="width: 130px">报考分数</div>
                <div style="width: 130px">校考排名</div>
                <div style="width: 130px">操作</div>
              </div>
              <div class="li" v-for="(item, index) in xiaokaoList" :key="index">
                <div class="index">{{index+1}}</div>
                <div class="name" :title="item.college_dictText" @click="queryById2(item.college)">{{item.college_dictText}}</div>
                <div class="name">{{item.dataName}}</div>
                <div class="score">{{item.score}}</div>
                <div class="score">{{item.rank}}</div>
                <div class="del" @click="delExams(item.recordId)">删除</div>
              </div>
            </div>
            <div class="form" v-if="show1">
              <div class="li">
                <div class="left">院校名称</div>
                <div class="main">
                  <el-select v-model="college" filterable placeholder="请选择">
                    <el-option
                      v-for="item in collectList"
                      :key="item.recordId"
                      :label="item.collegeName"
                      :value="item.recordId"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="right"></div>
              </div>
              <div class="li">
                <div class="left">报考专业</div>
                <div class="main">
                  <el-input
                    v-model="dataName"
                    placeholder="请输入报考专业"
                  ></el-input>
                </div>
                <div class="right"></div>
              </div>
              <div class="li">
                <div class="left">校考成绩</div>
                <div class="main">
                  <el-input
                    v-model="score"
                    placeholder="请输入报考分数"
                  ></el-input>
                </div>
                <div class="right"></div>
              </div>
              <div class="li">
                <div class="left">校考排名</div>
                <div class="main">
                  <el-input v-model="rank" placeholder="请输入排名"></el-input>
                </div>
                <div class="right"></div>
              </div>
              <div class="button">
                <el-button
                  type="primary"
                  style="background-color: #d3136c; color: #fff; border: 0"
                  @click="callback()"
                  >返回</el-button
                >
                <el-button
                  style="background-color: #d3136c; color: #fff; border: 0"
                  type="primary"
                  @click="saveSchool()"
                  >保存</el-button
                >
              </div>
            </div>
            <div
              class="add"
              style="color: #d3136c"
              v-if="!show1"
              @click="addSchool()"
            >
              <img src="../../assets/images/personalCenter/add.png" alt="" />
              添加校考信息
            </div>
          </div>
        </div>
        <div class="bottom">
          <el-button @click="nextOrSave()" v-if="itemIndex != 3">{{
            "保存"
          }}</el-button>
        </div>
      </div>
      <div
        class="right-main"
        v-if="mainIndex == 2"
        v-infinite-scroll="load"
        infinite-scroll-distance="1px"
        style="
          overflow: auto;
          max-height: 1040px;
          height: 1040px;
          scrollbar-width: none;
        "
      >
        <div
          class="section_our_solution"
          v-for="item in this.list"
          :key="item.recordId"
        >
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="our_solution_category">
                <div class="solution_cards_box">
                  <div class="solution_card">
                    <div class="hover_color_bubble"></div>
                    <div class="so_top_icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        viewBox="0 0 512 512"
                        height="50"
                        id="Layer_1"
                      >
                        <g>
                          <g>
                            <g>
                              <g>
                                <path
                                  fill="#fae19e"
                                  d="m47.478 452.317 295.441 19.76c5.511.369 10.277-3.8 10.645-9.31l28.393-424.517c.369-5.511-3.8-10.276-9.31-10.645l-295.441-19.76c-5.511-.369-10.276 3.8-10.645 9.31l-28.394 424.517c-.368 5.511 3.8 10.277 9.311 10.645z"
                                ></path>
                              </g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <path
                                          fill="#fff9e9"
                                          d="m17.5 504.177h226.14l79.96-79.605v-355.86c0-5.523-4.477-10-10-10h-296.1c-5.523 0-10 4.477-10 10v425.466c0 5.522 4.477 9.999 10 9.999z"
                                        ></path>
                                      </g>
                                      <path
                                        fill="#fff4d6"
                                        d="m313.601 58.712h-40c5.523 0 10 4.477 10 10v355.861l-.258 40.078 40.258-40.078v-355.861c0-5.523-4.477-10-10-10z"
                                      ></path>
                                    </g>
                                  </g>
                                </g>
                                <path
                                  fill="#ffeec2"
                                  d="m243.64 504.177v-70.253c0-5.523 4.477-10 10-10h69.96z"
                                ></path>
                              </g>
                            </g>
                            <g>
                              <path
                                fill="#fed23a"
                                d="m468.636 248.58-33.372.165v-50.826c0-9.183 7.463-16.662 16.673-16.708h.007c9.217-.046 16.693 7.371 16.693 16.562v50.807z"
                              ></path>
                              <path
                                fill="#54b1ff"
                                d="m451.96 504.177c-10.362-10.277-16.196-24.263-16.208-38.857l-.062-73.973c0-.644.524-1.169 1.171-1.173l30.038-.149c.647-.003 1.171.517 1.171 1.161l.062 74.079c.012 14.531-5.749 28.472-16.015 38.756z"
                              ></path>
                              <path
                                fill="#fdf385"
                                d="m451.959 469.333h-.01c-14.434.072-26.14-11.542-26.14-25.935v-213.527c0-6.778 5.477-12.283 12.255-12.316l27.626-.137c6.826-.034 12.378 5.49 12.378 12.316v213.436c0 14.38-11.687 26.091-26.109 26.163z"
                              ></path>
                              <path
                                fill="#faee6e"
                                d="m465.69 217.417-23.769.118c6.037.79 10.708 5.94 10.708 12.198v213.437c0 9.823-5.455 18.397-13.507 22.87 3.79 2.115 8.164 3.317 12.826 3.293h.01c14.422-.072 26.109-11.783 26.109-26.163v-213.436c.001-6.826-5.551-12.351-12.377-12.317z"
                              ></path>
                              <path
                                fill="#54b1ff"
                                d="m491.274 247.925-71.615.355c-7.305.036-13.226 5.968-13.226 13.248 0 7.281 5.921 13.153 13.226 13.117l58.389-.29v77.489c0 7.281 5.921 13.153 13.226 13.117 7.305-.036 13.226-5.968 13.226-13.248v-90.672c0-7.28-5.922-13.152-13.226-13.116z"
                              ></path>
                              <g>
                                <path
                                  fill="#3da7ff"
                                  d="m491.274 247.925-38.441.188-.167 26.311 25.381-.067v77.489c0 7.281 5.921 13.153 13.226 13.117 7.305-.036 13.226-5.968 13.226-13.248v-90.672c.001-7.282-5.921-13.154-13.225-13.118z"
                                ></path>
                              </g>
                            </g>
                          </g>
                          <g fill="#060606">
                            <path
                              d="m373.147 20.122-295.44-19.761c-9.631-.638-17.984 6.665-18.629 16.293l-2.311 34.557h-39.267c-9.649 0-17.5 7.851-17.5 17.5v425.466c0 9.649 7.851 17.5 17.5 17.5h226.141c1.96 0 3.902-.801 5.292-2.185l34.138-33.987c.347.074.701.133 1.065.157l58.282 3.898c9.302.614 18.005-6.952 18.629-16.293l28.393-424.515c.639-9.528-6.766-17.993-16.293-18.63zm-122.006 465.902v-52.1c0-1.378 1.122-2.5 2.5-2.5h51.9zm94.939-23.757c-.244 1.51-1.131 2.286-2.66 2.327l-46.28-3.096 31.752-31.611c1.414-1.407 2.209-3.32 2.209-5.315v-355.86c0-9.649-7.851-17.5-17.5-17.5h-77.993c-9.697 0-9.697 15 0 15h77.993c1.379 0 2.5 1.122 2.5 2.5v347.712h-62.46c-9.649 0-17.5 7.851-17.5 17.5v62.753h-218.641c-1.378 0-2.5-1.122-2.5-2.5v-425.465c0-1.378 1.122-2.5 2.5-2.5h178.168c9.697 0 9.697-15 0-15h-123.868l2.244-33.556c.244-1.511 1.131-2.286 2.661-2.327l295.44 19.76c1.511.244 2.287 1.131 2.328 2.661z"
                            ></path>
                            <path
                              d="m267.827 237.047h-204.553c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h204.553c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5z"
                            ></path>
                            <path
                              d="m267.827 289.332h-204.553c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h204.553c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5z"
                            ></path>
                            <path
                              d="m55.774 192.262c0 4.142 3.358 7.5 7.5 7.5h204.553c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-204.553c-4.142 0-7.5 3.358-7.5 7.5z"
                            ></path>
                            <path
                              d="m91.807 139.977c0 4.142 3.358 7.5 7.5 7.5h132.487c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-132.487c-4.142 0-7.5 3.358-7.5 7.5z"
                            ></path>
                            <path
                              d="m194.755 438.787c-13.489.036-26.978.065-40.467.086-4.534.007-9.067.013-13.6.016-8.215.006-13.75-1.643-15.59-10.679-1.556-7.64-12.364-6.613-14.464 0-5.19 16.337-13.774 9.936-18.582-1.053-4.797-10.963-6.027-23.233-8.122-34.9-1.54-8.573-14.506-6.17-14.732 1.994-.298 10.751-1.302 21.331-4.031 31.758-2.815 10.758-7.034 21.097-11.222 31.376-3.651 8.961 10.867 12.816 14.464 3.988 3.711-9.108 7.427-18.266 10.193-27.714 5.14 12.36 15.774 26.34 30.927 18.101 2.819-1.533 5.452-3.712 7.763-6.253 7.88 9.106 19.609 8.388 30.584 8.375 15.627-.02 31.254-.054 46.881-.095 9.649-.025 9.667-15.025-.002-15z"
                            ></path>
                            <path
                              d="m505.932 246.439c-3.897-3.878-9.255-5.867-14.695-6.014l-5.668.028v-10.719c0-6.529-3.878-13.427-9.433-16.862v-15.098c0-31.069-48.372-30.934-48.372.146v15.1c-5.659 3.498-9.455 9.741-9.455 16.852v10.982c-24.966 1.7-25.037 39.745.028 41.232.16 33.575.152 66.6-.028 100.737-.049 9.414 14.949 9.966 15 .079.18-34.166.188-67.22.029-100.823l37.211-.185s-.048 110.848-.048 160.784c0 24.338-37.219 24.5-37.219-.253l.013-13.677c.585-9.68-14.387-10.583-14.973-.904v12.834c0 11 3.402 20.316 9.988 26.869.586 15.693 7.198 30.878 18.369 41.956 3.205 3.18 7.642 2.208 10.744-.182 11.365-11.385 17.769-26.394 18.169-42.414 4.951-4.931 9.908-9.896 9.908-26.896l.006-68.351c12.97 3.689 26.494-6.348 26.494-19.946v-90.672c0-5.523-2.155-10.709-6.068-14.603zm-72.623-5.727v-10.841c0-2.219 1.523-4.08 3.573-4.633l30.025-.149c.84.208 1.615.605 2.243 1.231.915.911 1.419 2.123 1.419 3.414v10.794zm18.671-52c4.604 0 9.155 4.514 9.155 9.062v12.166l-18.372.091v-12.111c.001-5.053 4.133-9.183 9.217-9.208zm-.011 303.901c-3.487-4.942-6.009-10.531-7.417-16.406 2.322.503 4.674.765 7.027.765 2.627 0 5.253-.326 7.839-.957-1.374 5.964-3.892 11.587-7.449 16.598zm45.031-140.899c0 7.101-11.452 7.66-11.452.131 0 0 .013-70.974.021-77.48.005-4.196-3.483-7.509-7.558-7.509l-58.389.29c-7.242 0-7.073-11.331.074-11.366l71.615-.355c3.463.295 5.359 2.168 5.688 5.617v90.672z"
                            ></path>
                          </g>
                        </g>
                      </svg>
                      <i
                        style=""
                        class="el-icon-delete"
                        @click="deleteWish(item.recordId)"
                      >
                      </i>
                    </div>
                    <div class="solu_title">
                      <div v-if="wishingedit != item.recordId">
                        {{ item.wishName }}
                      </div>
                      <input
                        v-else
                        v-model="wishingeditname"
                        style="
                          outline: none;
                          padding-left: 10px;
                          width: 150px;
                          height: 30px;
                          border: 0;
                          border-radius: 5px;
                          margin-right: 10px;
                        "
                      />
                      <i
                        v-if="wishingedit != item.recordId"
                        @click="toEdit(item.recordId)"
                        style="cursor: pointer; margin-left: 30px"
                        class="el-icon-edit-outline"
                      ></i>
                      <div
                        style="
                          width: 50px;
                          height: 25px;
                          border: 1px #d3136c solid;
                          font-size: 14px;
                          line-height: 25px;
                          text-align: center;
                          margin-left: 30px;
                          border-radius: 5px;
                        "
                        v-else
                      >
                        <span
                          style="margin: 0 auto"
                          @click="editwishing(item.recordId)"
                          >保存</span
                        >
                      </div>
                    </div>
                    <div class="solu_description">
                      <p>
                        首选科目：{{ item.firstMajor_dictText }}<br />
                        报考批次：{{ item.theBatch_dictText }}<br />
                        报考省份：{{ item.province_dictText }}<br />
                      </p>
                      <button
                        class="read_more_btn"
                        type="button"
                        @click="toDetail(item.recordId)"
                      >
                        进入详情
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right-main" v-if="mainIndex == 3">叁</div>
      <div class="right-main" v-if="mainIndex == 4">肆</div>
      <div class="right-main" v-if="mainIndex == 5">伍</div>
    </div>
  </div>
</template>

<script>
import {
  countByStudent,
  getuserlist,
  province,
  yearly,
  artexam,
  subject,
  getdictNbr,
  studentEdit,
  queryById1,
  queryByStudent,
  deleteWishing,
  CollectqueryCollectCount,
  updateWishing,
  queryCollege,
  queryByStudentXiao,
  deleteById,
  addXiaoKao,
} from "@/api/index";

export default {
  data() {
    return {
      dataList: [],
      itemIndex: "1",
      mainIndex: "1",
      province: "",
      provinceList: [],
      yearly: "",
      yearlyList: [],
      studentList: {},
      studentDetail: {},
      artWard: [],
      options: [],
      firstMajor: "1",
      firstMajors: [],
      otherMajor: [],
      cities: [],
      sex: "1",
      sexList: [],
      name: "",
      schoolName: "",

      drow: false,
      enScore: "",
      ywScore: "",
      cultureScore: "",
      cultureRank: "",
      majorScore: "",
      majorRank: "",
      sxScore: "",
      wishingCount: 0,
      NumberOfConcerns: "",
      list: [],

      wishingedit: "",
      wishingeditname: "",

      show1: false,

      xiaokaoList: [],
      collectList: [],
      college: "",
      dataName: "",
      score: "",
      rank: "",
    };
  },
  mounted() {
    this.getstudentList();
    this.getProvince();
    this.getYearly();
    this.getMajor();
    this.getdictNbr();
    this.getList();
    this.getCollect();
  },
  methods: {
    queryById2(data) {
      this.$router.push({
        path: "/collegeDetail",
        query: { id: data },
      });
    },
    getqueryByStudentXiao() {
      let studentId = this.studentList.recordId;
      console.log("studentId", studentId);
      queryByStudentXiao(studentId).then((res) => {
        this.xiaokaoList = res.data;
      });
    },

    getCollect() {
      queryCollege().then((res) => {
        console.log("asdasda", res);
        this.collectList = res.data;
      });
    },
    delExams(recordId) {
      let data = {
        recordId: recordId
      }
      deleteById(data).then(res => {
        this.getqueryByStudentXiao()
      }).catch(err => {
        console.log(err)
      })
    },
    saveSchool() {
      let data = {
        student: this.studentDetail.recordId,
        college: this.college,
        dataName: this.dataName,
        score: this.score,
        rank: this.rank,
      }
      console.log(data);
      addXiaoKao(data).then((res) => {
        this.college = "";
        this.dataName = "";
        this.score = "";
        this.rank = "";
        this.getqueryByStudentXiao()
      });
      this.show1 = false;
    },
    callback() {
      this.show1 = false;
    },
    addSchool() {
      console.log(123);

      this.show1 = true;
    },
    editwishing(id) {
      let data = {
        recordId: id,
        wishName: this.wishingeditname,
      };
      updateWishing(data).then((res) => {
        if (res.code == 200) {
          this.getList();
          this.wishingedit = "";
          this.wishingeditname = "";
        }
      });
    },
    toEdit(data) {
      console.log(data);
      this.wishingedit = data;
    },
    deleteWish(recordId) {
      let data = {
        recordId: recordId,
      };
      deleteWishing(data).then((res) => {
        this.getList();
        this.getStudentDetail();
      });
    },
    load() {},
    toDetail(recordId) {
      this.$router.push({
        path: "/detail",
        query: { data: recordId },
      });
    },
    async getList() {
      let student = JSON.parse(localStorage.getItem("userList")).recordId;
      await queryByStudent(student).then((res) => {
        console.log(res);
        this.list = res.data.reverse();
      });
    },
    toVolunteer() {
      // this.$router.push({ path: "/MyVolunteer" });
      this.mainIndex = 2;
    },
    getStudentDetail() {
      let recordId = this.studentList.recordId;
      let userInfo = this.studentList.userInfo;
      queryById1(recordId).then((res) => {
        this.studentDetail = res.data;
      });
      countByStudent(recordId).then((res) => {
        this.wishingCount = res.data;
      });
      CollectqueryCollectCount(userInfo).then((res) => {
        console.log("asdas", res);
        this.NumberOfConcerns = res.data;
      });
    },
    getdictNbr() {
      const dict = "sex";
      getdictNbr(dict).then((res) => {
        this.sexList = res.data;
      });
    },
    async getMajor() {
      try {
        let major = await subject();
        for (let index = 0; index < major.data.length; index++) {
          if (major.data[index].subjectType == 1) {
            this.firstMajors.push(major.data[index]);
          } else if (major.data[index].subjectType == 2) {
            this.cities.push(major.data[index]);
          }
        }
      } catch (error) {}
    },
    changeProvince() {},
    getCheckedNodes() {
      const checkedNodes = this.$refs.cascader.getCheckedNodes();
      console.log(checkedNodes);
    },
    getArtexam(data) {
      artexam(data).then((res) => {
        console.log(res);
        this.options = res.data;
      });
    },
    getYearly() {
      yearly().then((res) => {
        this.yearlyList = res.data;
      });
    },
    getProvince() {
      province().then((res) => {
        console.log(res);
        this.provinceList = res.data;
      });
    },
    nextOrSave() {
      let data = {
        cardInfo: this.studentList.cardInfo,
        userInfo: this.studentList.userInfo,
        recordId: this.studentList.recordId,
        province: this.province,
        yearly: this.yearly,
        artWard: this.artWard[0],
        subWard: this.artWard[1],
        firstMajor: this.firstMajor,
        otherMajor: this.otherMajor,
        userSex: this.sex,
        userName: this.name,
        school: this.schoolName,
        ywScore: this.ywScore,
        enScore: this.enScore,
        cultureScore: this.cultureScore,
        majorScore: this.majorScore,
        sxScore: this.sxScore,
        majorRank: this.majorRank,
        cultureRank: this.cultureRank,
      };
      studentEdit(data).then((res) => {
        this.itemIndex = "1";
        this.getstudentList();
        this.$notify({
          type: "success",
          title: res.msg,
          message: res.data,
          showClose: false,
        });
      });
    },
    getstudentList() {
      let userInfo = localStorage.getItem("userId");
      getuserlist(userInfo).then((res) => {
        console.log(res);
        this.studentList = res.data[0];
        this.name = this.studentList.userName;
        // localStorage.setItem("username", this.name);
        this.$store.commit("setUserList", this.name);
        this.schoolName = this.studentList.school;
        this.province = this.studentList.province;
        this.yearly = this.studentList.yearly;
        this.drow = true;

        this.artWard[0] = this.studentList.artWard;
        if (this.studentList.subWard == null) {
        } else {
          this.artWard[1] = this.studentList.subWard;
        }

        this.firstMajor = this.studentList.firstMajor;
        this.otherMajor = this.studentList.otherMajor;
        this.sex = this.studentList.userSex;
        this.ywScore = this.studentList.ywScore;
        this.enScore = this.studentList.enScore;
        this.cultureScore = this.studentList.cultureScore;
        this.majorScore = this.studentList.majorScore;
        this.sxScore = this.studentList.sxScore;
        this.getStudentDetail();
        this.getqueryByStudentXiao();
        this.getArtexam(this.studentList.province);
      });
    },
    handleSelect(key, keyPath) {
      this.itemIndex = key;
    },
    toMain(index) {
      console.log(index);
      this.mainIndex = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.centent {
  width: 1280px;
  height: 1100px;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 60px;
  display: flex;
  user-select: none;
  .left {
    width: 355px;
    .left-top {
      height: 485px;
      background: #fff;
      .avatar {
        height: 260px;
        width: 295px;
        margin: 0 30px;
        border-bottom: 1px solid #ededed;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 18px;
        .avatar-image {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          background-repeat: no-repeat;
          background-size: 100px 100px;
          border: 3px solid #d3136c;
          margin-top: 30px;
        }
        .username {
          margin-top: 15px;
        }
        .open-member {
          margin-top: 15px;
          width: 150px;
          height: 40px;
          background: #d3136c;
          color: #fff;
          border-radius: 20px;
          border: 0;
        }
      }
      .information {
        width: 295px;
        height: 165px;
        margin: 30px;
        display: flex;
        flex-direction: column;
        .info-box {
          height: 40px;
          width: 100%;
          display: flex;
          font-size: 18px;
          .info-title {
            margin-right: 20px;
          }
          .info-main {
            font-weight: 600;
          }
        }
      }
    }
    .left-main {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      color: #fff;
      .left-main-div {
        height: 85px;
        width: 167px;
        padding: 20px 0;
        background: #d3136c;
        display: flex;
        flex-direction: column;
        text-align: center;
        .main-top {
          height: 75%;
          font-size: 40px;
        }
        .main-bottom {
          height: 25%;
          font-size: 18px;
        }
      }
      .div-color {
        background: #1085ac;
      }
    }
    .left-bottom {
      margin-top: 20px;
      width: 295px;
      height: 420px;
      padding: 15px 30px;
      background: #fff;
      display: flex;
      flex-direction: column;
      .left-bottom-div {
        height: 70px;
        line-height: 70px;
        font-size: 18px;
        color: #a5a4a4;
        img {
          vertical-align: middle;
          margin-right: 30px;
        }
      }
      .left-bottom-div-active {
        height: 70px;
        line-height: 70px;
        font-size: 18px;
        color: #d3136c;
        img {
          vertical-align: middle;
          margin-right: 30px;
        }
      }
    }
  }
  .right {
    width: 905px;
    margin-left: 20px;
    background: #fff;
    .right-main {
      width: 905px;
      margin: 30px 0px;
      display: flex;
      // flex-wrap: wrap;
      flex-direction: column;
      .section_our_solution {
        height: 160px;
        margin-bottom: 20px;
      }
      .section_our_solution .row {
        align-items: center;
        width: 820px;
        height: 100px;
        margin: 15px 0 15px 20px;
      }

      .our_solution_category {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }

      .our_solution_category .solution_cards_box {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .solution_cards_box .solution_card {
        flex: 0 50%;
        background: #fff;
        box-shadow: 0 2px 4px 0 rgba(136, 144, 195, 0.2),
          0 5px 15px 0 rgba(37, 44, 97, 0.15);
        border-radius: 15px;
        margin: 8px;
        padding: 10px 15px;
        position: relative;
        z-index: 1;
        overflow: hidden;
        min-height: 120px;
        transition: 0.7s;
        position: relative;
      }

      .solution_cards_box .solution_card:hover {
        background: #d3136c;
        color: #fff;
        transform: scale(1.1);
        z-index: 9;
      }

      .solution_cards_box .solution_card:hover .solu_title div {
        display: flex;
        color: #fff;
      }
      .solution_cards_box .solution_card:hover .solu_description {
        position: absolute;
        top: 30px;
        left: 134px;
      }
      .solution_cards_box .solution_card:hover .solu_description p {
        color: #fff;
      }

      .solution_cards_box .solution_card:before {
        content: "";
        position: absolute;
        // background: rgb(233 65 143 / 5%);
        width: 170px;
        // height: 400px;
        z-index: -1;
        transform: rotate(42deg);
        right: -56px;
        top: -23px;
        border-radius: 35px;
      }

      .solution_cards_box .solution_card:hover .solu_description button {
        background: #fff !important;
        color: #d3136c;
      }

      .solution_card .so_top_icon {
        i {
          position: absolute;
          right: 20px;
        }
      }
      .solu_title {
        display: flex;
        align-items: center;
        margin-top: 15px;
      }
      .solution_card .solu_title div {
        color: #212121;
        font-size: 1.3rem;
        margin-top: 13px;
        margin-bottom: 13px;
        display: flex;
      }
      .solution_card .solu_description {
        position: absolute;
        top: 30px;
        left: 134px;
      }
      .solution_card .solu_description p {
        font-size: 15px;
        margin-bottom: 15px;
      }

      .solution_card .solu_description button {
        border: 0;
        border-radius: 15px;
        background: linear-gradient(
          140deg,
          #42c3ca 0%,
          #42c3ca 50%,
          #42c3cac7 75%
        ) !important;
        color: #fff;
        font-weight: 500;
        font-size: 1rem;
        padding: 5px 16px;
        position: absolute;
        top: 50px;
        right: -340px;
      }

      .our_solution_content div {
        text-transform: capitalize;
        margin-bottom: 1rem;
        font-size: 2.5rem;
      }

      .hover_color_bubble {
        position: absolute;
        background: rgba(233 65 143/ 15%);
        width: 100rem;
        height: 100rem;
        left: 0;
        right: 0;
        z-index: -1;
        top: 16rem;
        border-radius: 50%;
        transform: rotate(-36deg);
        left: -18rem;
        transition: 0.7s;
      }

      .solution_cards_box .solution_card:hover .hover_color_bubble {
        top: 0rem;
      }

      .solution_cards_box .solution_card .so_top_icon {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: #fff;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .solution_cards_box .solution_card .so_top_icon img {
        width: 40px;
        height: 50px;
        object-fit: contain;
      }

      /*start media query*/
      @media screen and (min-width: 320px) {
        .sol_card_top_3 {
          position: relative;
          top: 0;
        }

        .our_solution_category {
          width: 100%;
          margin: 0 auto;
        }

        .our_solution_category .solution_cards_box {
          flex: auto;
        }
      }

      @media only screen and (min-width: 768px) {
        .our_solution_category .solution_cards_box {
          flex: 1;
        }
      }

      @media only screen and (min-width: 1024px) {
        .sol_card_top_3 {
          position: relative;
          top: -3rem;
        }

        .our_solution_category {
          width: 81%;
          margin: 0 auto;
        }
      }
      .main {
        margin: 30px;
        width: 845px;
        .main-1 {
          .li {
            height: 60px;
            display: flex;
            align-items: center;
            .left {
              width: 200px;
            }
            .main {
              .el-cascader {
                width: 400px;
              }
              ::v-deep .el-radio {
                .el-radio__input.is-checked .el-radio__inner {
                  border-color: #d3136c;
                  background: #d3136c;
                }
                .el-radio__input.is-checked + .el-radio__label {
                  color: black;
                }
              }
              ::v-deep .el-checkbox-group {
                .el-checkbox__input.is-checked .el-checkbox__inner {
                  border-color: #d3136c;
                  background: #d3136c;
                }
                .el-checkbox__input.is-checked + .el-checkbox__label {
                  color: black;
                }
              }
              .el-select {
                width: 400px;
              }
              .el-input {
                width: 400px;
              }
            }
          }
          .list {
            width: 800px;
            margin: 0 auto;
            .title {
              display: flex;
              width: 100%;
              height: 30px;
              line-height: 30px;
              border-bottom: #ebeef5 1px solid;
              div {
                padding-left: 10px;
              }
            }
            .li {
              display: flex;
              width: 100%;
              height: 60px;
              line-height: 60px;
              border-bottom: #ebeef5 1px solid;

              &:hover > div {
                background: #f5f7fa;
              }
              div {
                padding-left: 10px;
              }
              .index {
                width: 90px;
                background: #fff;
                text-align: center;
              }
              .name {
                width: 130px;
                background: #f7f7f7;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                cursor: pointer;
                &:hover {
                  color: #d3136c;
                }
              }
              .score {
                width: 130px;
                background: #f7f7f7;
              }
              .del {
                width: 130px;
                background: #f7f7f7;
                color: #d3136c;
                cursor: pointer;
              }
            }
            /*  :hover {
              background: #f5f7fa;
            } */
          }
          .add {
            height: 50px;
            width: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            cursor: pointer;
            img {
              width: 30px;
              height: 30px;
              margin-right: 10px;
            }
          }
          .form {
            width: 800px;
            .li {
              display: flex;
              .left {
                width: 300px;
              }
            }
            .button {
              width: 680px;
              margin: 0 auto;
              display: flex;
              justify-content: center;
              .el-button {
                width: 286px;
              }
            }
          }
        }
      }
      .bottom {
        .el-button {
          width: 680px;
          height: 45px;
          border: 0;
          background: #d3136c;
          color: #fff;
          margin: 0 auto;
          font-size: 18px;
          display: block;
          margin: auto;
          border-radius: 10px;
        }
      }
    }
  }
}
</style>