<template>
  <div class="majorMain">
    <div class="top">
      <div class="img">
        <img src="../../../assets/images/majordetail/img.png" alt="" />
      </div>
      <div class="main">
        <div class="name">{{ detailList.majorName }}</div>
        <div class="info">
          <span v-if="detailList.eduYear">{{ detailList.eduYear }}</span
          ><span v-if="detailList.eduLevel_dictText">{{
            detailList.eduLevel_dictText
          }}</span
          ><span v-if="detailList.parentId_dictText">{{
            detailList.parentId_dictText
          }}</span>
        </div>
      </div>
    </div>
    <div class="tab">
      <div :class="mainIndex == 1 ? 'label' : 'label1'" @click="tab(1)">
        基本介绍
      </div>
      <!--  <div :class="mainIndex == 2 ? 'label' : 'label1'" @click="tab(2)">
        就业分析
      </div>
      <div :class="mainIndex == 3 ? 'label' : 'label1'" @click="tab(3)">
        开设院校
      </div> -->
    </div>
    <div class="line"></div>
    <div v-if="mainIndex == 1" class="content">
      <div class="title" v-if="detailList.introduce">
        <div class="img"></div>
        <div class="name">
          {{ detailList.parentId_dictText }}
        </div>
      </div>
      <div class="text" v-if="detailList.introduce">
        {{ detailList.introduce }}
      </div>
      <div class="title" v-if="detailList.trainPurpose">
        <div class="img"></div>
        <div class="name">培养方向</div>
      </div>
      <div class="text" v-if="detailList.trainPurpose">
        {{ detailList.trainPurpose }}<br />
        <div
          v-if="detailList.trainRequire"
          v-html="detailList.trainRequire"
        ></div>
      </div>
      <div class="title" v-if="detailList.mainClass">
        <div class="img"></div>
        <div class="name">主要课程</div>
      </div>
      <div class="text">{{ detailList.mainClass }}</div>
      <div class="title" v-if="masterWay">
        <div class="img"></div>
        <div class="name">该专业研究方向</div>
      </div>
      <div
        class="text"
        style="display: flex; flex-wrap: wrap; flex-direction: row"
      >
        <span style="margin-bottom: 20px;" v-for="item in masterWay" :key="item">{{ item }}</span>
      </div>
      <div class="title" v-if="detailList.maleRate || detailList.femaleRate">
        <div class="img"></div>
        <div class="name">数据统计</div>
      </div>
      <div class="text" v-if="detailList.maleRate || detailList.femaleRate">
        <span style="display: flex; width: 300px"
          >男女比例
          <div style="color: #d3136c; margin-left: 46px">
            男{{ detailList.maleRate }}% &nbsp;&nbsp;&nbsp;&nbsp; 女{{
              detailList.femaleRate
            }}%
          </div></span
        >
      </div>
      <nodata v-if="detailList.firstMajor == null"></nodata>

    </div>
    <div v-if="mainIndex == 2" class="content"></div>
    <div v-if="mainIndex == 3" class="content"></div>
  </div>
</template>

<script>
import { queryById3 } from "@/api/index";
import nodata from "../../../components/NoData";
export default {
  components: { nodata },
  data() {
    return {
      detailList: {},
      mainIndex: 1,
    };
  },
  computed: {
    masterWay() {
      if (this.detailList.masterWay) {
        let arr = [];
        arr.push(...this.detailList.masterWay.split("、"));
        return arr;
      }
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    tab(index) {
      this.mainIndex = index;
    },
    getData() {
      let recordId = this.$route.query.id;
      queryById3(recordId).then((res) => {
        this.detailList = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.majorMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
  margin-bottom: 60px;
  .top {
    width: 1280px;
    height: 223px;
    border-radius: 20px 20px 20px 20px;
    // background: black;
    background: url("../../../assets/images/majordetail/background.png");
    margin: 40px 0;
    display: flex;
    align-content: center;
    align-items: center;
    position: relative;
    user-select: none;
    .img {
      width: 135px;
      height: 135px;
      margin: 45px 40px;
      background: white;
      border-radius: 50%;
      position: relative;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 0px;
      div {
        height: 45px;
        line-height: 45px;
      }
      .name {
        font-size: 21px;
        color: #333;
        margin-bottom: 30px;
        font-weight: bold;
      }
      .label,
      .info {
        display: flex;
        span {
          min-width: 90px;
          height: 46px;
          //   padding: 3px 10px 3px 0;
          text-align: center;
          margin-right: 20px;
          //   color: #707070;
          border-radius: 10px;
          font-size: 18px;
          background: #fff;
          padding: 0 15px;
        }
      }
    }
  }
  .tab {
    display: flex;
    width: 1280px;
    .label {
      width: 240px;
      height: 64px;
      text-align: center;
      line-height: 64px;
      background: #d3136c;
      color: #fff;
      margin-right: 32px;
      border-radius: 20px 20px 0 0;
      user-select: none;
      cursor: pointer;
    }
    .label1 {
      width: 240px;
      height: 64px;
      text-align: center;
      line-height: 64px;
      background: #fed7e9;
      color: #fff;
      margin-right: 32px;
      border-radius: 20px 20px 0 0;
      user-select: none;
      cursor: pointer;
    }
  }
  .line {
    width: 1280px;
    height: 1px;
    border-top: 1px solid #a5a4a4;
    margin-bottom: 30px;
  }
  .content {
    width: 1280px;
    background: #fff;
    display: flex;
    padding-bottom: 30px;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;

    .title {
      display: flex;
      height: 85px;
      width: 1220px;
      align-items: center;
      .img {
        width: 19px;
        height: 21px;
        background: url("../../../assets/images/majordetail/tip.png");
      }
      .name {
        margin-left: 14px;
        font-size: 18px;
        font-weight: 700;
      }
    }
    .text {
      // max-height: 140px;
      width: 1220px;
      // background: #d3136c;
      div {
        ::v-deep p {
          margin-top: 10px;
        }
      }

      span {
        display: block;
        padding: 20px 30px;
        margin-right: 20px;
        background: #fed7e9;
        border-radius: 10px 10px 10px 10px;
        font-size: 18px;
      }
    }
  }
}
</style>