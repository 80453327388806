const state = {
    // 管理新手指引状态
}
const mutations = {
}
const actions = {
}
const getters = {
}

export default {
    state,
    mutations,
    actions,
    getters
}