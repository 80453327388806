<template>
  <div class="collegedetail">
    <div class="title">
      <div class="img">
        <img :src="detailList.collegeLogo" alt="" />
      </div>
      <div class="main">
        <div class="name">{{ detailList.collegeName }}</div>
        <div class="label">
          <span v-for="item in detailList.tagNames" :key="item">{{
            item
          }}</span>
        </div>
        <div class="info">
          <span>{{ detailList.province_dictText }}</span
          ><span>{{ detailList.eduLevel_dictText }}</span
          ><span>{{ detailList.natureType_dictText }}</span>
        </div>
      </div>
      <div class="add" @click="addSchool(detailList.recordId)">
        <img src="../../../assets/images/collegedetail/add.png" alt="" />
        {{ isConcern ? "已关注" : "关注" }}
      </div>
    </div>
    <div class="main">
      <div class="top">
        <div class="tab">
          <div :class="mainIndex == 1 ? 'label' : 'label1'" @click="tab(1)">
            基本介绍
          </div>
          <!-- <div :class="mainIndex == 2 ? 'label' : 'label1'" @click="tab(2)">
            招录信息
          </div>
          <div :class="mainIndex == 3 ? 'label' : 'label1'" @click="tab(3)">
            招生数据
          </div>
          <div :class="mainIndex == 4 ? 'label' : 'label1'" @click="tab(4)">
            录取分析
          </div> -->
        </div>
        <div class="line"></div>
      </div>
      <div v-if="mainIndex == 1" class="content">
        <div class="left">
          <div class="top">
            <div class="lebs">
              <div
                class="leb"
                :title="'创建时间：' + this.detailList.establishYear"
              >
                <img
                  src="../../../assets/images/collegedetail/1.png"
                  alt=""
                /><span
                  >创建时间：{{
                    this.detailList.establishYear
                      ? this.detailList.establishYear
                      : "——"
                  }}</span
                >
              </div>
              <div
                class="leb"
                :title="'学校性质：' + detailList.natureType_dictText"
              >
                <img src="../../../assets/images/collegedetail/2.png" alt="" />
                <span>学校性质：{{ detailList.natureType_dictText }}</span>
              </div>
              <div
                class="leb"
                :title="'学历层次：' + detailList.eduLevel_dictText"
              >
                <img
                  src="../../../assets/images/collegedetail/3.png"
                  alt=""
                /><span>学历层次：{{ detailList.eduLevel_dictText }}</span>
              </div>
              <div
                class="leb"
                :title="'院校类型：' + detailList.collegeClasz_dictText"
              >
                <img
                  src="../../../assets/images/collegedetail/4.png"
                  alt=""
                /><span>院校类型：{{ detailList.collegeClasz_dictText }}</span>
              </div>
              <div
                class="leb"
                :title="
                  '所在地：' +
                  detailList.province_dictText +
                  detailList.cityArea
                "
              >
                <img
                  src="../../../assets/images/collegedetail/5.png"
                  alt=""
                /><span
                  >所在地：{{ detailList.province_dictText
                  }}{{ detailList.cityArea }}</span
                >
              </div>
              <div class="leb" :title="'主管部门：' + detailList.belongTo">
                <img
                  src="../../../assets/images/collegedetail/6.png"
                  alt=""
                /><span>主管部门：{{ detailList.belongTo }}</span>
              </div>
              <div
                class="leb"
                :title="
                  '博士点：' + detailList.doctorFst + detailList.doctorSec
                "
              >
                <img
                  src="../../../assets/images/collegedetail/7.png"
                  alt=""
                /><span
                  >博士点：{{
                    detailList.doctorFst + detailList.doctorSec
                  }}</span
                >
              </div>
              <div
                class="leb"
                :title="
                  '硕士点：' + detailList.masterFst + detailList.masterSec
                "
              >
                <img
                  src="../../../assets/images/collegedetail/8.png"
                  alt=""
                /><span
                  >硕士点：{{
                    detailList.masterFst + detailList.masterSec
                  }}</span
                >
              </div>
            </div>
          </div>
          <div class="left-main">
            <div
              v-html="detailList.introduce ? detailList.introduce : '暂无内容'"
            ></div>
          </div>
        </div>
      </div>
      <div v-if="mainIndex == 2" class="content">
        <div class="left">
          <div class="top">
            <div class="leb">招录信息</div>
          </div>
        </div>
      </div>
      <div v-if="mainIndex == 3" class="content">
        <div class="left">
          <div class="top">
            <div class="leb">招生数据</div>
          </div>
        </div>
      </div>
      <div v-if="mainIndex == 4" class="content">
        <div class="left">
          <div class="top">
            <div class="leb">录取分析</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryById2, collect, isCollect } from "@/api/index";
export default {
  name: "CollegeDetail",
  data() {
    return {
      detailList: {},
      mainIndex: 1,
      isConcern: false,
    };
  },
  mounted() {
    this.getData();
    this.getisCollect();
  },
  methods: {
    getisCollect() {
      let college = this.$route.query.id;
      isCollect(college).then((res) => {
        this.isConcern = res.data;
      });
    },
    addSchool(data) {
      // console.log(data);
      let college = data;
      collect(college).then(res => {

        
        if (res.code == 200) {
          this.getisCollect();
        } else if (res.code == 403) {
          this.$store.commit("setLoginState", { state: true, islogin: false });
        }
      });
    },
    tab(index) {
      this.mainIndex = index;
    },
    getData() {
      let recordId = this.$route.query.id;
      queryById2(recordId).then((res) => {
        this.detailList = res.data;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.collegedetail {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    width: 1280px;
    height: 223px;
    margin-top: 40px;
    margin-bottom: 40px;
    background: linear-gradient(86deg, #ffffff 0%, #ffffff 48%, #ff8ac0 100%);
    border-radius: 5px;
    display: flex;
    align-content: center;
    align-items: center;
    position: relative;
    user-select: none;
    .img {
      width: 135px;
      height: 135px;
      margin: 45px 40px;
      // background: #ccc;
      // border-radius: 50%;
      img {
        // width: 135px;
        height: 135px;
        // margin: 45px 40px;
        background: #ccc;
        // border-radius: 50%;
      }
    }
    .main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 0px;
      div {
        height: 45px;
        line-height: 45px;
      }
      .name {
        font-size: 21px;
        color: #333;
        font-weight: bold;
      }
      .label,
      .info {
        display: flex;
        span {
          padding: 3px 10px 3px 0;
          color: #707070;
          font-size: 18px;
        }
      }
    }
    .add {
      user-select: none;
      cursor: pointer;
      width: 135px;
      height: 50px;
      border: 0;
      background: white;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 50px;
      img {
        width: 23px;
        height: 23px;
        margin-right: 10px;
      }
    }
  }
  .main {
    width: 1280px;
    margin-bottom: 60px;
    user-select: none;
    .top {
      width: 1280px;
      margin-bottom: 30px;
      .tab {
        display: flex;
        .label {
          width: 240px;
          height: 64px;
          text-align: center;
          line-height: 64px;
          background: #d3136c;
          color: #fff;
          margin-right: 32px;
          border-radius: 20px 20px 0 0;
          user-select: none;
          cursor: pointer;
        }
        .label1 {
          width: 240px;
          height: 64px;
          text-align: center;
          line-height: 64px;
          background: #fed7e9;
          color: #fff;
          margin-right: 32px;
          border-radius: 20px 20px 0 0;
          user-select: none;
          cursor: pointer;
        }
      }
      .line {
        width: 1280px;
        height: 1px;
        border-top: 1px solid #a5a4a4;
      }
    }
    .content {
      width: 1280px;
      display: flex;
      .left {
        width: 1280px;
        min-height: 500px;
        border-radius: 20px;
        background: #fff;
        margin-right: 32px;
        .lebs {
          width: 1220px;
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          flex-wrap: wrap;
          margin: 30px 30px 20px 30px;
          .leb {
            width: 226px;
            height: 66px;
            border-radius: 10px;
            margin-bottom: 20px;
            padding: 0 20px;
            background: #feecf4;
            display: flex;
            align-items: center;

            cursor: pointer;

            img {
              margin-right: 14px;
            }
            span {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        .left-main {
          width: 1280px;
          max-height: 525px;
          overflow-y: scroll;
          scrollbar-color: white;
          scrollbar-color: white;
          div {
            margin: 0px 30px 0px 30px;
            overflow: hidden;
            ::v-deep p {
              margin-bottom: 10px;
              text-indent: 2rem;
              line-height: 30px;
            }
          }
        }
      }
      .right {
        width: 372px;
        .top {
          width: 372px;
          height: 694px;
          border-radius: 20px;
          background: #fff;
          position: relative;
          overflow: hidden;
          .top-title {
            // font-weight: bold;
            font-weight: bold;
            width: 100px;
            height: 35px;
            margin-left: 20px;
            // text-align: center;
            line-height: 35px;
            margin-top: 25px;
          }
          /*   &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 164px;
            height: 164px;
            background: linear-gradient(49deg, #ffffff 25%, #f5a8cc 100%);
            border-radius: 10px 10px 10px 100%;
          } */
        }
        .bottom {
          width: 372px;
          height: 135px;
          border-radius: 20px;
          background: #fff;
          overflow: hidden;
          .title1 {
            font-weight: bold;
            width: 100px;
            height: 35px;
            margin-left: 20px;
            // text-align: center;
            line-height: 35px;
            margin-top: 25px;
          }
          .address {
            // width: 372px;
            margin-top: 15px;
            margin-left: 20px;
          }
        }
      }
    }
  }
}
</style>