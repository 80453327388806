
const HomeView = ()=>import("@/views/HomeView");
const FindCollege = ()=>import("@/views/FindCollege");
const QuerySpeciality = ()=>import("@/views/QuerySpeciality");
const NoopsycheFill = ()=>import("@/views/NoopsycheFill");
const SimulateFill = ()=>import("@/views/SimulateFill")
const QueryPolicy = ()=>import("@/views/QueryPolicy")
const SimplifyTools = () => import("@/views/SimplifyTools");

const dynamicRouter = [
    {
        path:'home',
        name:'home',
        component:HomeView,
        meta:{
            requiresAuth: false,
            title:'首页'
        }
    },
    {
        path:'findcollege',
        name:'findcollege',
        component:FindCollege,
        meta:{
            requiresAuth: false,
            title:'找大学'
        }
    },
    {
        path:'queryspeciality',
        name:'queryspeciality',
        component:QuerySpeciality,
        meta:{
            requiresAuth: false,
            title:'查专业'
        }
    },
    {
        path:'noopsychefill',
        name:'noopsychefill',
        component:NoopsycheFill,
        meta:{
            requiresAuth: false,
            title:'智能填报'
        }
    },
    {
        path:'simulatefill',
        name:'simulatefill',
        component:SimulateFill,
        meta:{
            requiresAuth: false,
            title:'模拟填报'
        }
    },
   /*  {
        path:'querypolicy',
        name:'querypolicy',
        component:QueryPolicy,
        meta:{
            requiresAuth: false,
            title:'看政策'
        }
    },*/
   /*  {
        path:'simplifytools',
        name:'simplifytools',
        component:SimplifyTools,
        meta:{
            requiresAuth: false,
            title:'艺考小工具'
        }
    }, */
]
export default dynamicRouter;